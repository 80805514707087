//
// Carousel
//


.carousel.carousel-custom {
    // Indicators
    .carousel-indicators {
        align-items: center;
        position: static;
        z-index: auto;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            transform: none;
            opacity: 1;

            &.active {
                transform: none;
                opacity: 1;
            }
        }

        // Dots style
        &.carousel-indicators-dots {
            li {
                border-radius: 0;
                background-color: transparent;
                height: $carousel-custom-dots-indicator-active-size;
                width: $carousel-custom-dots-indicator-active-size;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                &:after {
                    display: inline-block;
                    content: " ";
                    @include border-radius(50%);
                    transition: all $carousel-custom-indicator-transition-speed ease;
                    background-color: $carousel-custom-indicator-default-bg-color;
                    height: $carousel-custom-dots-indicator-default-size;
                    width: $carousel-custom-dots-indicator-default-size;
                }

                &.active {
                    background-color: transparent;

                    &:after {
                        transition: all $carousel-custom-indicator-transition-speed ease;
                        height: $carousel-custom-dots-indicator-active-size;
                        width: $carousel-custom-dots-indicator-active-size;
                        background-color: $carousel-custom-indicator-active-bg-color;
                    }
                }
            }
        }

        // Bullet style
        &.carousel-indicators-bullet {
            li {
                transition: all $carousel-custom-indicator-transition-speed ease;
                background-color: transparent;
                border-radius: $carousel-custom-bullet-indicator-default-size;
                height: $carousel-custom-bullet-indicator-default-size;
                width: $carousel-custom-bullet-indicator-default-size;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                &:after {
                    display: inline-block;
                    content: " ";
                    transition: all $carousel-custom-indicator-transition-speed ease;
                    background-color: $carousel-custom-bullet-indicator-default-bg-color;
                    border-radius: $carousel-custom-bullet-indicator-default-size;
                    height: $carousel-custom-bullet-indicator-default-size;
                    width: $carousel-custom-bullet-indicator-default-size;
                }

                &.active {
                    transition: all $carousel-custom-indicator-transition-speed ease;
                    background-color: transparent;
                    height: $carousel-custom-bullet-indicator-default-size;
                    width: $carousel-custom-bullet-indicator-active-width;

                    &:after {
                        transition: all $carousel-custom-indicator-transition-speed ease;
                        height: $carousel-custom-bullet-indicator-default-size;
                        width: $carousel-custom-bullet-indicator-active-width;
                        background-color: $carousel-custom-bullet-indicator-active-bg-color
                    }
                }
            }
        }
    }

    // Theme colors
    @each $name, $value in $theme-colors {
        .carousel-indicators-active-#{$name} {
            li.active:after {
                background-color: $value !important;
            }
        }
    }

    // Stretch mode
    &.carousel-stretch {
        height: 100%;
        display: flex;
        flex-direction: column;

        .carousel-inner {
            flex-grow: 1;
        }

        .carousel-item {
            height: 100%;
        }

        .carousel-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }   
}
