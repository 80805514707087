.gate-price-table {
    padding: 0!important;
    table, th, td {
        border: 1px solid;
    }
    table {
        width: 100%;
        margin-top: 20px;
    }
    th {
        background-color: rgba(245, 248, 250, 0.8);
    }
    td,th {
        padding: 5px!important;
        font-size: 12px;
        text-align: center;
    }
    &--big {
        td,th {
            font-size: 14px;
        }
    }
}
