//
// Buttons Base
//

// Button
.btn {
  // Reset outline
  outline: none !important;

  // Reset focus shadow
  &:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
    box-shadow: none !important;
  }

  // Remove border
  &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(#{$btn-padding-y} + #{$btn-border-width}) calc(#{$btn-padding-x} + #{$btn-border-width});

    &.btn-lg {
      padding: calc(#{$btn-padding-y-lg} + #{$btn-border-width}) calc(#{$btn-padding-x-lg} + #{$btn-border-width});
    }

    &.btn-sm {
      padding: calc(#{$btn-padding-y-sm} + #{$btn-border-width}) calc(#{$btn-padding-x-sm} + #{$btn-border-width});
    }
  }

  // Link
  &.btn-link {
    border: 0;
    border-radius: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-decoration: none;
    font-weight: $btn-font-weight;
  }

  // Outline dashed
  &.btn-outline-dashed {
    border: 1px dashed $border-dashed-color;
  }

  // Outline default
  &.btn-outline-default {
    border: 1px solid $input-border-color;
  }

  // Flush
  &.btn-flush {
    @include button-reset();
  }

  // Flex
  &.btn-flex {
    display: inline-flex;
    align-items: center;
  }

  // Align start
  &.btn-trim-start {
    justify-content: flex-start !important;
    padding-left: 0 !important;
  }

  // Align start
  &.btn-trim-end {
    justify-content: flex-end !important;
    padding-right: 0 !important;
  }
}

// Icons
.btn {
  // Font icon
  i {
    display: inline-flex;
    font-size: $font-size-base;
    padding-right: 0.35rem;
    vertical-align: middle;
    line-height: 0;
  }

  // Svg icon
  .svg-icon {
    flex-shrink: 0;
    line-height: 0;
    margin-right: 0.5rem;
  }

  // Icon only button
  &.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: $input-height;
    width: $input-height;

    // Remove border
    &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
      border: 0;
    }

    // Sizes
    &.btn-sm {
      height: $input-height-sm;
      width: $input-height-sm;

    }

    &.btn-lg {
      height: $input-height-lg;
      width: $input-height-lg;
    }

    &.btn-circle {
      border-radius: 50%;
    }

    i,
    .svg-icon {
      padding: 0;
      margin: 0;
      line-height: 1;
    }
  }
}

// Hover effects
.btn.btn-hover-rise {
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10%);
    transition: transform 0.3s ease;
  }
}

.btn.btn-hover-scale {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
}

.btn.btn-hover-rotate-end {
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(4deg);
    transition: transform 0.3s ease;
  }
}

.btn.btn-hover-rotate-start {
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(-4deg);
    transition: transform 0.3s ease;
  }
}

.change-position {
  display: flex;
  gap: 20px;

  &--left {
    align-self: center;
    width: 13px;
    white-space: nowrap;
  }

  &--right {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .arrow-up {
      cursor: pointer;
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        border-right: 2px solid rgb(161, 163, 182);
        border-top: 2px solid rgb(161, 163, 182);
        rotate: -45deg;
        width: 10px;
        height: 10px;
        transition: .3s ease-in-out;
      }

      &:hover {
        div {
          border-right: 2px solid black;
          border-top: 2px solid black;
        }
      }
    }

    .arrow-down {
      cursor: pointer;
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        border-right: 2px solid rgb(161, 163, 182);
        border-bottom: 2px solid rgb(161, 163, 182);
        rotate: 45deg;
        width: 10px;
        height: 10px;
        transition: .3s ease-in-out;
      }


      &:hover {
        div {
          border-right: 2px solid black;
          border-bottom: 2px solid black;
        }
      }
    }
  }
}
.depthFormula-warning {
  border-radius: 0.475rem;
  background-color: #F5F8FA;
  padding: 10px;
  border: 1px solid #F1416C;
  width: 100%;
  text-align: center;
  p {
    font-size: 10px;
    margin: 0;
  }
}
