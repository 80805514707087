//
// Main
//


// Body
body {
  background-color: $page-bg;
}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    padding: 0 get($content-spacing, desktop);
  }

  // Wrapper
  .wrapper {
    transition: padding-left get($aside-config, transition-speed) ease, margin-right get($aside-config, transition-speed) ease;

    // Aside default enabled and aside fixed modes
    .aside-enabled.aside-fixed & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, width);
    }

    // Aside default enabled, aside fixed and aside minimize modes
    .aside-enabled.aside-fixed[data-kt-aside-minimize="on"] & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, minimized-width);
    }

    // Fixed header mode
    .header-fixed & {
      padding-top: get($header-config, fixed, height, desktop);
    }

    // Fixed header & fixed toolbar modes
    .header-fixed.toolbar-fixed & {
      padding-top: calc(#{get($header-config, fixed, height, desktop)} + var(--kt-toolbar-height));
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    max-width: none;
    padding: 0 get($content-spacing, tablet-and-mobile);
  }

  // Wrapper
  .wrapper {
    // Fixed header mode
    .header-tablet-and-mobile-fixed & {
      padding-top: get($header-config, fixed, height, tablet-and-mobile);
    }

    // Fixed header & fixed toolbar modes
    .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
      padding-top: calc(#{get($header-config, fixed, height, tablet-and-mobile)} + var(--kt-toolbar-height-tablet-and-mobile));
    }
  }
}

.pdf-preview {
  &-top {
    &-item {
      border-right: 3px solid #1D446F;
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: #2B5884;
      font-size: 18px;

      img {
        width: 90%;
      }

      &:last-of-type {
        border-right: unset;
      }
    }
  }

  .bg-blue {
    background: #5E89CE;
    color: #ffffff;
    text-transform: uppercase;
  }

  .bg-grey-border {
    border: 2px solid grey;
    color: #2B5884;
    font-weight: 600;
    font-size: 22px;

    &-right {
      border-right: 2px solid grey;
    }
  }

  .bg-blue-border {
    border: 2px solid #5E89CE;
    color: #2B5884;
    font-weight: 600;
    font-size: 22px;

    &-right {
      border-right: 2px solid #5E89CE;
    }
  }

  .blue-item {
    border: 2px solid #5E89CE;

    div {
      background: #5E89CE;
      color: #ffffff;
      font-size: 18px;
      min-width: 180px;
      text-transform: uppercase;
      white-space: nowrap;
      font-weight: 600;
      padding-right: .75rem;
      width: 50%;
    }

    p {
      margin-bottom: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #2B5884;
      text-transform: uppercase;
      font-size: 18px;
      padding-right: .75rem;
    }
  }

  .pdf-right {
    p {
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.pdf-sizes {
  position: relative;

  p {
    position: absolute;
    bottom: 100px;
    z-index: 1000;
    font-weight: 500;
    font-size: 20px;

    &.width {
      left: 51%;
      bottom: 25px;
    }
    &.height {
      right: -54px;
      bottom: 42%;
    }
    &.rightArea {
      right: 33px;
      bottom: 49%;
    }
    &.leftArea {
      left: 177px;
      bottom: 51%;
    }
    &.lintel {
      left: 51%;
      top: 26%;
    }
    &.deep {
      right: 214px;
      top: 11%;
    }
  }
}